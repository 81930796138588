import React, { PureComponent } from "react";
import { Popover as BootstrapPopover, PopoverBody, PopoverProps as BootstrapPopoverProps } from "reactstrap";
import styles from "./styles.scss";
import { Placement } from "popper.js";

/**
 * See the [[BootstrapPopoverProps]] interface for more details.
 */
export interface PopoverProps extends BootstrapPopoverProps {
  children: any;
  trigger?: string;
  style?: any;
  className?: string;
  isOpen?: boolean;
  placement?: Placement;
  target: string;
  toggle?(): void;
  boundariesElement?: Element | "scrollParent" | "viewport";
}

export interface PopoverState {
  isOpen: boolean;
}

/**
 * This element functions as a more advanced tooltip component. You can add any custom functionality
 * inside of it and it will automatically adjust adjust its positioning based on the viewport boundaries
 * @param props Bootstrap Popover properties from reactstrap extended to accept a trigger string
 */
export default class Popover extends PureComponent<PopoverProps, PopoverState> {
  constructor(props: PopoverProps) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }
  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };
  render() {
    const {
      children,
      style = {},
      className = "",
      toggle = this.toggle,
      trigger = "legacy",
      isOpen = this.state.isOpen,
      placement = "top",
      target = "Popover",
    } = this.props;

    return (
      <BootstrapPopover
        style={style}
        className="rotoql-popover"
        placement={placement}
        isOpen={isOpen}
        target={target}
        toggle={toggle}
        trigger={trigger}
        boundariesElement="viewport"
      >
        <PopoverBody className={`rotoql-popover__body ${className || ""}`}>{children}</PopoverBody>
        <style jsx global>
          {styles}
        </style>
      </BootstrapPopover>
    );
  }
}
