import React, { Component } from "react";
import Icon from "@mdi/react";
import { mdiLock } from "@mdi/js";
import styles from "./styles.scss";

const SharpIcon = require("public/icon/sharp-icon.png?sizes[]=20,sizes[]=40,sizes[]=60");

interface LoginToViewProps {
  className?: string;
  showLoginText?: boolean;
  openLoginModal: (loginTabIndex: number) => void;
  fieldName?: string;
  isSharpStat?: boolean;
}

export default class LoginToView extends Component<LoginToViewProps, {}> {
  render() {
    const { showLoginText, className, openLoginModal, fieldName, isSharpStat } = this.props;

    return (
      <React.Fragment>
        <div
          className={`${className ? className : ""} game-row-login__bounding-con `}
          onClick={() => openLoginModal(1)}
        >
          <div style={{ height: "33px" }}>
            <div className="game-row-login__con">
              <Icon path={mdiLock} size="14px" color="#004198" />
              <div className="game-row-login__gray-bar" />
              {isSharpStat ? (
                <img
                  data-src={SharpIcon.src}
                  data-srcset={SharpIcon.srcSet}
                  alt="Sharp Tier Icon"
                  className="game-row-login__sharp-icon lazyload"
                />
              ) : null}
            </div>
            {showLoginText ? <div className="game-row-login__login-text">Signup For Free</div> : null}
            {fieldName ? <div className="game-row-login__login-text">{fieldName}</div> : null}
          </div>
        </div>
        <style jsx global>
          {styles}
        </style>
      </React.Fragment>
    );
  }
}
