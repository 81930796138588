import React, { useContext } from "react";
import Link from "next/link";
import Icon from "@mdi/react";
import { mdiMenuLeft, mdiMenuRight } from "@mdi/js";
import { BetQLService } from "@rotoql/common-services";
import { isEmpty } from "lodash";

import { WEB_SPORTS_LIST, DEFAULT_SPORT, WebSportItem } from "../../constants";
import styles from "./styles.scss";
import TeamLogo from "../Team/TeamLogo";
import { renderRating } from "../../services/ValueDataService";
import { rfc3986EncodeURIComponent } from "../../services/UtilService";
import { UpgradeToView } from "../GamesTable/RowComponents";
import { SubscriptionContext } from "../../services/SubscriptionService";
import { getGameLeague, useGameProgressAndChannel } from "src/services/EventService";

const { getOurPick, getTotalDisplay, getTeamRecord } = BetQLService;

const GameCard = ({ game = {} }: { game: any }) => {
  const ctx = useContext(SubscriptionContext);
  const { homeTeam, awayTeam } = game;

  // Get the currentSportObj, we set a default here to satisfy typescript
  const currentSportObj: WebSportItem =
    WEB_SPORTS_LIST.find((sport: WebSportItem) => {
      return sport.key === game.league.name;
    }) || DEFAULT_SPORT;

  // get the sportKey for use in URLs
  const sportKey: string = currentSportObj.url;
  const slugId = rfc3986EncodeURIComponent(game.slugId);

  // Get the information we need to display the green arrow
  const favoriteFilterKey: string = currentSportObj.favoriteLine;
  const periodFilter: string =
    favoriteFilterKey === "spread" || favoriteFilterKey === "moneyline" || favoriteFilterKey === "total"
      ? `FG${favoriteFilterKey}`
      : favoriteFilterKey;
  const isNew = currentSportObj[`has${periodFilter}PowerRating`];
  const ourPick: "home" | "away" = getOurPick(game, "value", favoriteFilterKey, currentSportObj);

  // Get the lines to display based on favoriteLine of the sport
  const getBetInfo = () => {
    const overUnderText = getTotalDisplay(game.currentFGtotalAwayData, true);
    if (favoriteFilterKey === "spread") {
      if (game.currentFGspreadHomeData !== null && game.currentFGspreadAwayData !== null) {
        if (game.currentFGspreadHomeData === game.currentFGspreadAwayData) {
          return `Even ${overUnderText}`;
        }
        if (game.currentFGspreadHomeData < 0) {
          return `${homeTeam?.preferredAbbreviation} ${game.currentFGspreadHomeDisplay} ${overUnderText}`;
        }
        return `${awayTeam?.preferredAbbreviation} ${game.currentFGspreadAwayDisplay} ${overUnderText}`;
      }
      return `${game.currentFGspreadAwayDisplay} ${overUnderText}`;
    } else if (favoriteFilterKey === "moneyline") {
      if (game.currentFGmoneylineHomeData !== null && game.currentFGmoneylineAwayData !== null) {
        if (game.currentFGmoneylineHomeData < 0) {
          return `${homeTeam?.preferredAbbreviation} ${game.currentFGmoneylineHomeDisplay} ${overUnderText}`;
        }
        return `${awayTeam?.preferredAbbreviation} ${game.currentFGmoneylineAwayDisplay} ${overUnderText}`;
      }
      return `${game.currentFGmoneylineAwayDisplay} ${overUnderText}`;
    }
  };

  let homeAbbreviation: string = homeTeam?.preferredAbbreviation ?? "";
  let awayAbbreviation: string = awayTeam?.preferredAbbreviation ?? "";
  let tournamentSlugId: string | undefined;
  if (currentSportObj.isTennis) {
    homeAbbreviation = homeTeam?.country;
    awayAbbreviation = awayTeam?.country;
    tournamentSlugId = game?.tournament?.slugId ? rfc3986EncodeURIComponent(game.tournament.slugId) : undefined;
  }

  const awayRank = game?.awayTeamSeed ?? game?.awayTeam?.teamStats?.rank ?? "";
  const homeRank = game?.homeTeamSeed ?? game?.homeTeam?.teamStats?.rank ?? "";

  const progressAndChannel = useGameProgressAndChannel(game);
  const hasAccess = ctx.hasSubscriptionAccess(currentSportObj.key);

  // TODO this likely won't happen, but just in case
  if (isEmpty(game)) {
    return null;
  }

  return (
    <div className="rotoql-game-card">
      <div className="rotoql-game-card__row-teams">
        <section className="rotoql-game-card__awayteam-container" aria-label="Away Team">
          <TeamLogo
            containerClassName="rotoql-game-card__team-logo-container"
            className="rotoql-game-card__team-logo"
            teamAbbreviation={awayAbbreviation}
            leagueName={game.league.name}
            size={40}
          />
        </section>
        <div className="rotoql-game-card__rating">
          {game.freeGame || hasAccess ? (
            renderRating(
              game[`max${isNew ? "New" : ""}${periodFilter}Trend`],
              false,
              "rotoql-game-card__rating-container"
            )
          ) : (
            <UpgradeToView
              notRow
              style={{ width: "103px" }}
              className="rotoql-game-card__rating-container-locked"
              showText={true}
              trackingProps={{
                screen: "HomeView",
                page: "Game Card",
                source: "Game Card Rating",
                sport: game.league.name,
                gameId: game.id,
                awayTeam: game.awayTeam?.fullName,
                homeTeam: game.homeTeam?.fullName,
              }}
            />
          )}
        </div>
        <section className="rotoql-game-card__hometeam-container" aria-label="Home Team">
          <TeamLogo
            containerClassName="rotoql-game-card__team-logo-container"
            className="rotoql-game-card__team-logo"
            teamAbbreviation={homeAbbreviation}
            leagueName={game.league.name}
            size={40}
          />
        </section>
      </div>
      <div className="rotoql-game-card__row-details">
        <div className="rotoql-game-card__awayteam-container">
          <div className="rotoql-game-card__team-abbreviation-text">
            {currentSportObj.isCollege ? awayTeam?.fullName : awayTeam?.lastName}
            <span className="rotoql-game-card__rank-container">{awayRank}</span>
            {ourPick === "away" && (game.freeGame || hasAccess) ? (
              <Icon path={mdiMenuLeft} size={1.25} color={"#02a16c"} />
            ) : null}
          </div>
          {game.league.name !== "XFL" ? (
            <div className="rotoql-game-card__team-record-text">
              ({getTeamRecord(game.awayTeam, getGameLeague(game))}) | ATS ({game.statAwayTeamATSWinsDisplay}-
              {game.statAwayTeamATSLossesDisplay})
            </div>
          ) : null}
        </div>
        <div className="rotoql-game-card__hometeam-container">
          <div className="rotoql-game-card__team-abbreviation-text">
            {ourPick === "home" && (game.freeGame || hasAccess) ? (
              <Icon path={mdiMenuRight} size={1.25} color={"#02a16c"} />
            ) : null}
            <span className="rotoql-game-card__rank-container right">{homeRank}</span>
            {currentSportObj.isCollege ? homeTeam?.fullName : homeTeam?.lastName}
          </div>
          {game.league.name !== "XFL" ? (
            <div className="rotoql-game-card__team-record-text">
              ({getTeamRecord(game.homeTeam, getGameLeague(game))}) | ATS ({game.statHomeTeamATSWinsDisplay}-
              {game.statHomeTeamATSLossesDisplay})
            </div>
          ) : null}
        </div>
      </div>
      <div className="rotoql-game-card__record-container">
        <div className="rotoql-game-card__start-time">{progressAndChannel}</div>
        <div className="rotoql-game-card__lines">{getBetInfo()}</div>
      </div>
      <div className="rotoql-game-card__button-container">
        <Link passHref href={`/${sportKey}/game-predictions/${slugId}`} legacyBehavior>
          <a className="rotoql-game-card__game-details-text">See Full Analysis</a>
        </Link>
        {currentSportObj.isTennis && tournamentSlugId ? (
          <Link href={`/${sportKey}/tournament/${tournamentSlugId}`} passHref legacyBehavior>
            <a className="rotoql-game-card__game-details-text">See {game.tournament?.name} Details</a>
          </Link>
        ) : null}
      </div>
      <style jsx global>
        {styles}
      </style>
    </div>
  );
};

export default GameCard;
