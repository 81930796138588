import React, { PureComponent } from "react";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";

import styles from "./styles.scss";
import Popover from "../../Popover";

interface StatHighlightProps {
  value: number;
  color: "red" | "blue";
  highlightText: string;
  isBold?: boolean;
  isMobile?: boolean;
  popoverText?: string;
  popoverTitle?: string;
  thresholdFunction(value: number): boolean;
}

interface StatHighlightState {
  popoverOpen: boolean;
  id: string;
}

export default class StatHighlight extends PureComponent<StatHighlightProps, StatHighlightState> {
  constructor(props: StatHighlightProps) {
    super(props);
    this.state = {
      popoverOpen: false,
      id: `stathighlighter_${Math.round(Math.random() * 1000000)}`,
    };
  }
  render() {
    const { isMobile, popoverText, popoverTitle, thresholdFunction, value, color, highlightText, isBold } = this.props;
    const { popoverOpen, id } = this.state;
    const highlight = thresholdFunction(value);
    let content: any = null;
    if (highlight) {
      content = (
        <div className={"games-table-column__text-container"} id={id}>
          <p
            className={`game-row-highlight__con game-row-highlight__con--${color} ${
              isMobile ? "game-row-highlight__con--mobile" : ""
            }`}
          >
            {`${value}% ${highlightText}`}
          </p>
        </div>
      );
    } else {
      content = (
        <div className={"games-table-column__text-container"} id={id}>
          <p
            className={
              isMobile
                ? "games-table-mobile-row__line-text"
                : `games-table-column__line-text ${isBold && "games-table-column__line-text--bold"}`
            }
          >
            {value !== null ? `${value}%` : "--"}
          </p>
        </div>
      );
    }
    if (popoverTitle && highlight) {
      return (
        <React.Fragment>
          {content}
          <Popover
            style={{
              width: "350px",
            }}
            placement="right"
            isOpen={popoverOpen}
            target={id}
            toggle={() => this.setState({ popoverOpen: !popoverOpen })}
            boundariesElement="viewport"
            trigger="legacy"
          >
            <div
              className="game-row-highlight__popover-close-button"
              onClick={() => this.setState({ popoverOpen: false })}
            >
              <Icon path={mdiClose} size="22px" color="#000" />
            </div>
            <div className="game-row-highlight__popover-title-text">{popoverTitle}</div>
            <div className="game-row-highlight__popover-body-text">{popoverText}</div>
          </Popover>
          <style jsx global>
            {styles}
          </style>
        </React.Fragment>
      );
    }
    return content;
  }
}
