import React from "react";
import { BetQLSportItem } from "@rotoql/common-services";

import StatHighlight from "./StatHighlight";

interface SharpPlay {
  isMobile?: boolean;
  sharpPercent: number;
  teamName: string;
  ticketCount: number;
  isBold?: boolean;
  sportObj: BetQLSportItem;
}

const SharpPlay = (props: SharpPlay) => (
  <StatHighlight
    value={props.sharpPercent}
    color="blue"
    highlightText="SHARP BET"
    isBold={props.isBold}
    isMobile={props.isMobile}
    popoverTitle={`${props.sharpPercent}% of the sharp money is on the  ${props.teamName}`}
    popoverText="Follow professional bettors by comparing where the most money has been placed on each game.  A sharp play will be indicated once a significant amount of bets have been placed, which varies per sport."
    thresholdFunction={() => {
      if (
        props.sharpPercent !== null &&
        props.ticketCount !== null &&
        props.sharpPercent >= props.sportObj.sharpPercentThreshold &&
        props.ticketCount >= props.sportObj.sharpCountThreshold
      ) {
        return true;
      }
      return false;
    }}
  />
);

export default SharpPlay;
