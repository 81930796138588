import React from "react";
import { BetQLSportItem } from "@rotoql/common-services";

import StatHighlight from "./StatHighlight";

interface PublicPlay {
  isMobile?: boolean;
  public: number;
  teamName: string;
  isBold?: boolean;
  ticketCount: number;
  sportObj: BetQLSportItem;
}

const PublicPlay = (props: PublicPlay) => (
  <StatHighlight
    isMobile={props.isMobile}
    value={props.public}
    color="red"
    highlightText="PUBLIC BET"
    isBold={props.isBold}
    popoverTitle={`${props.public}% of the public is on the ${props.teamName}`}
    popoverText="Going against the public plays for most bets is usually better over the long haul. A public play will be indicated once a significant amount of bets have been placed, which varies per sport."
    thresholdFunction={() => {
      if (
        props.public !== null &&
        props.ticketCount !== null &&
        props.public >= props.sportObj.publicThreshold &&
        props.ticketCount >= props.sportObj.publicCountThreshold
      ) {
        return true;
      }
      return false;
    }}
  />
);

export default PublicPlay;
