import React, { Component } from "react";
import Icon from "@mdi/react";
import { mdiLock } from "@mdi/js";

import { SubscriptionContext } from "../../../services/SubscriptionService";
import styles from "./styles.scss";

const SharpIcon = require("public/icon/sharp-icon.png?sizes[]=20,sizes[]=40,sizes[]=60");

interface UpgradeToViewProps {
  style?: any;
  containerStyle?: any;
  className?: string;
  showText?: boolean;
  fieldName?: string;
  isSharpStat?: boolean;
  trackingProps: any;
  notRow?: boolean;
}

export default class UpgradeToView extends Component<UpgradeToViewProps, {}> {
  render() {
    const { style, containerStyle, showText, className, fieldName, isSharpStat, trackingProps, notRow } = this.props;
    return (
      <SubscriptionContext.Consumer>
        {({ redirectToPricing }) => (
          <React.Fragment>
            <div
              style={containerStyle}
              className={`${className ? className : ""} game-row-login__bounding-con `}
              onClick={() => redirectToPricing(trackingProps, "Games Table " + trackingProps.source)}
            >
              <div style={style}>
                <div style={notRow ? { height: "auto" } : {}} className="game-row-login__con">
                  <Icon path={mdiLock} size="14px" color="#004198" />
                  <div className="game-row-login__gray-bar" />
                  {isSharpStat ? (
                    <img
                      data-src={SharpIcon.src}
                      data-srcset={SharpIcon.srcSet}
                      alt="Sharp Tier Icon"
                      className="game-row-login__sharp-icon lazyload"
                    />
                  ) : null}
                </div>
                {showText ? (
                  <div style={notRow ? { height: "auto" } : {}} className="game-row-login__login-text">
                    Premium Access
                  </div>
                ) : null}
                {fieldName ? <div className="game-row-login__login-text">{fieldName}</div> : null}
              </div>
            </div>
            <style jsx global>
              {styles}
            </style>
          </React.Fragment>
        )}
      </SubscriptionContext.Consumer>
    );
  }
}
